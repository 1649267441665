.FeedbackMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 328px;

  h2 {
    margin-top: 32px;
    margin-bottom: 8px; }

  p {
    display: flex;
    flex-direction: column; }

  a {
    text-decoration: none; }

  &__Text {
    margin-bottom: 24px;
    text-align: center; } }
