.CompanyJob {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 108px);
  width: 100%;

  * {
    box-sizing: border-box; }

  &__Banner {
    position: relative;
    margin-bottom: 15px;
    width: 100%; }

  &__LogoWrapper {
    position: absolute;
    bottom: -25px;
    right: 20px;
    background-color: #8051E0;
    height: 85px;
    width: 85px;
    border-radius: 8px;
    box-shadow: 0px 5px 0px #DDD;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 85%; } }

  &__Content {
    text-align: left;
    padding: 16px 16px; }

  &__Requirements {
    padding: 12px 25px;
    width: 100%;
    margin-bottom: 40px;

    li {
      text-align: left;
      width: 100%;
      color: #9E9E9E;
      font-weight: 600;
      list-style: square outside;

      &::marker {
        text-align: center;
        color: #7E51DF;
        border-radius: 0px;
        font-size: 25px; } } }

  &__Locations {
    padding: 20px 3px;
    display: flex;
    justif-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;

    li {
      color: #391DDD;
      background-color: #E9E5FF;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      margin: 0px 20px 20px 0px; } }

  &__Action {
    background-color: #FFF;
    padding: 15px 20px 40px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-shadow: 5px 5px 10px #AAA;
    display: flex;
    align-items: center;
    justify-content: center; }

  &__Spinner {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .Button {
      border-radius: 4px; } } }

.CompanyJobJames {
  &__Vojo {
    display: flex;
    flex-direction: column;
    width: 328px;
    height: fit-content;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center; }

    h2 {
      text-align: center;
      margin-bottom: 24px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #212121; } }

  &__Message {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(2) {
      padding-bottom: 24px;
      border-bottom: 1px #DDDDDD solid;
      margin-bottom: 24px; }

    a {
      margin-top: 8px;
      color: #391ddd;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      text-decoration: none; }

    &__Wrapper {
      text-align: center;
      width: 100%;
      max-width: 328px;

      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #391DDD; }

      h3 {
        color: #391DDD;
        line-height: 20px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0.2px; }

      p {
        color: #9E9E9E;
        line-height: 18px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px; }
      &:first-of-type {
        margin-bottom: 6px; } } } }

.Snackbar {
  display: flex;
  flex-direction: column;
  width: 328px;
  height: fit-content;
  margin-top: 8px; }
