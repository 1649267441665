.JobDetail {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 108px);
  width: 100%;

  &__Banner {
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    &__Info {
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding: 12px 20px;
      position: absolute;
      top: 0;
      width: 100%;

      &__Tag {
        background: #FF5477;
        border-radius: 4px;
        box-sizing: border-box;
        height: fit-content;
        padding: 7px 10px; }


      &__Slots {
        background: #FFE010; } }


    &__Button {
      align-items: center;
      bottom: -24px;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%; } }

  &__Info {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;

    &__Block {
      margin-bottom: 15px;

      &__Subtitle {
        margin-top: 6px; }

      &__List {
        margin-top: 10px;
        width: 100%; }

      &__Title {
        margin-top: 3px; } } }

  &__List {
    padding-left: 20px; } }

.JobDescription {
  margin: 10px 0 20px 0; }

.CompanyName {
  justify-self: right;
  width: 100%;
  margin-top: 25px; }

.CourseSuggestion {
  margin-bottom: 2em;
  margin-top: 1em;

  &__Adornment {
    background: #391ddd;
    width: 5em;
    height: 0.5em;
    margin-bottom: 2em; } }

