.MainTitleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  margin-bottom: 14px;

  &__Center {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    justify-content: center; }

  &__Wrapped {
    margin-bottom: 10px; } }
