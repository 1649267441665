.MinHeight {
  min-height: calc(100vh - 168px);
  width: 100%;
  @media screen and ( min-width: 1024px ) {
    min-height: calc(100vh - 108px); } }

.Coupons {
  display: flex;
  flex-direction: column;
  border-bottom: 2px #DDDDDD solid;
  margin-top: 30px;
  padding-bottom: 48px;
  @media screen and ( max-width: 1024px ) {
    padding-left: 20px;
    padding-right: 20px; }

  &__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px; }

  &__CardsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;

    &__Wrapped {
      margin-bottom: 12px; } }

  &__Section {
    display: flex;
    flex-direction: column;
    &__Form {
      width: 100%;

      &__Button {
        margin-top: 40px; }

      &__Wrapped {
        margin-bottom: 40px; }
      &:last-child {
        margin-bottom: 0px; } }

    &__validatedCoupons {
      width: 100%;
      margin-top: 40px; } } }

.CoursesFree {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  height: fit-content;
  min-height: 110px;
  &__Wrapper {
    margin-top: 4px; }
  &__Button {
    margin-top: 24px;
    width: 200px;
    height: 18px;
    min-width: fit-content; } }
.ModalIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 32px; }

.CouponMessageTitle {
  text-align: center;
  margin-top: 7vh; }

.CouponMessageSubTitle {
  text-align: center;
  margin-top: 2vh; }
