.ApplicationModules {
  min-height: calc(100vh - 108px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__Header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 10px 16px 16px 16px;
    width: 100%;

    &__Application {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      &__Block {
        &:last-of-type {
          cursor: pointer; } } }

    &__Step {
      margin-bottom: 10px; }

    &__StepDescription {
      &__Title {
        margin-bottom: 5px; } } }

  &__Message {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__border {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px #DDDDDD solid; }

    &:first-of-type {
      margin-bottom: 32px; }

    &:nth-of-type(2) {
      padding-bottom: 24px;
      border-bottom: 1px #DDDDDD solid;
      margin-bottom: 32px; }

    a {
      margin-top: 8px;
      color: #391ddd;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      text-decoration: none; }

    &__Wrapper {
      margin-bottom: 14px;
      text-align: center;
      width: 100%;
      max-width: 328px;

      h2 {
        color: #212121;
        margin: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.25px;
        font-family: Montserrat, sans-serif; }
      h3 {
        color: #391DDD;
        line-height: 20px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0.2px; }

      p {
        color: #666666;
        line-height: 18px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px; }
      &:first-of-type {
        margin-bottom: 6px; }

      a {
        color: #666666;
        font-weight: 400;
        text-decoration: underline;
        margin-right: 3px; } }

    &__Button {
      text-decoration: none;
      margin-top: 20px;
      width: 100%;
      max-width: 232px; }

    &__Approved {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      max-width: 318px;
      margin: 32px 0;
      margin-top: 52px;
      &__Wrapper {
        margin-top: 18px; }

      &__Title {
        max-width: 200px; }

      &__CheckIcon {
        background-color: #391DDD;
        width: 84px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin-bottom: 32px; } } }

  &__Modules {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;
    width: 100%;

    &__List {
      margin: 20px 0;
      width: 100%;

      &:first-of-type {
        margin-top: 0; }

      &:last-of-type {
        margin-bottom: 0; }

      &__Title {
        margin: 10px 0 15px 0; }

      &__Cards {
        &__Block {
          margin: 15px 0;

          &:first-of-type {
            margin-top: 0; }

          &:last-of-type {
            margin-bottom: 40px; }

          &__Error {
            margin-top: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #E10000;
            letter-spacing: -0.2px;

            a {
              cursor: pointer;
              text-decoration: underline;
              font-weight: 600;
              margin-left: 3px; } } } } } } }

.ApplicationCompany {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 8px;

  &__Title {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__Icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; } }

.WhatsContainer {
  width: 100%;
  margin-top: auto; }

.Whats {
  background: #5DB553;
  margin: 20px 0px;
  box-sizing: border-box;
  color: white;
  border-radius: 4px;
  width: 150px;
  display: flex;
  align-items: center;
  padding: 9px 24px;
  justify-content: space-between;
  cursor: pointer; }

.DubtsTitle {
  margin-bottom: 6px; }

.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1; }

.Content {
  margin-top: 20px; }

.GateKeeper {
  margin-bottom: 14px;
  text-align: center;
  h2 {
    color: #666666;
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-family: 'Rubik', sans-serif; } }

.DevolutiveCourseSuggestion {
  margin-bottom: 2em;
  margin-top: 4em; }
