.Forms {
  margin: 24px 0;

  &__Form {
    min-height: calc(100vh - 168px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Description {
      margin-bottom: 20px; }

    &__Fields {
      display: flex;
      width: 100%;
      flex-wrap: wrap; }

    &__Field {
      margin: 15px 0;
      width: 100%;

      &__GroupDescription {
        margin: 6px 0px 24px !important; }

      &__SectionTitle {
        margin-bottom: 15px !important; } }

    &__Title {
      margin-bottom: 6px; }

    &__PartialLargerField {
      width: 65%;
      height: 10%;
      padding: 12px 5px 0 0; }

    &__PartialSmallerField {
      padding: 12px 0 0 5px;
      width: 30%;
      height: 10%; }

    &__Submit {
      width: 100%; } } }

.FormCompany {
  display: flex;
  width: 100%;
  margin-top: 8px; }

.Category {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 20px;

  &__Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px; }

  &__Icon {
    width: 40px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; } }

.Cep {
  width: 100%;
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;

  &__Clickable {
    cursor: pointer; } }

.ModalBank {
  margin-top: 8px;
  margin-bottom: 14px;

  &__Wrapper {
    margin-bottom: 16px; } }

.Digit {
  margin: 2px 0 0 10px;
  span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 16px; } }
